import {
  Button,
  Drawer,
  Steps,
  Row,
  Col,
  message,
  Spin,
  Descriptions,
  Space,
  Divider,
} from "antd";

import "./leaflet.css";
import L, { marker } from "leaflet";
import "leaflet.motion/dist/leaflet.motion.min.js";
import { polylineDecorator } from "leaflet-polylinedecorator";
import boat from "@/assets/chuan.svg";
import guoIcon from "@/assets/zhuan.svg";
import circleIcon from "@/assets/circle_icon.svg";
import startIcon from "@/assets/qi.svg";
import endIcon from "@/assets/zhong.svg";
import cangkuIcon from "@/assets/cangchu.svg";
import triangle1 from "@/assets/triangle1.svg";
import { useEffect, useState } from "react";
import { getTime } from "@/utils/common";
import {
  mainRecord, // 停靠港口信息
  gettrajectory,
  burialPoint,
} from "../../api";
import dateFormat from "dateformat";
import { v4 as uuid } from "uuid";
import {
  addLayer_map,
  addLayer_marker,
  addLayer_billTrak,
  setEyeStatus,
} from "./draw";
import "./index.scss";
import moment from "moment";
import OnlineVeidoModal from "@/components/OnlineVedioModal";
import DetailDrawer from "./mapComponents/trackDrawer/detail";
import { getValueFromParamsUrl } from "@/utils/common";
import StorageDrawer from "./mapComponents/trackDrawer/storage";
// import TrackPlayer from "leaflet-trackplayer";
var map = null;

var LinghtGroup = null;

// 地图标注图层（轨迹起点和终点）
let markerGroup_SE = null;

// 地图标注图层（靠港）
let markerGroup_port = null;

// 地图标注图层 (轨迹)
let lineGroup_line = null;

// 地图标注图层 (圆圈)
let circleGroup_circle = null;

// 地图标注图层（动画）
let movingGroup_boat = null;

// 闪烁标注图层（动画）
let markLinghtGroup = null;

// 添加轨迹图层
let ly_track = [];

// marker 图层
let ly_marker = [];

let pathname = window.location.href.split("=")[1];
let id = pathname?.split("&")?.[0];
const offsetDrawer = 464;
const Track = (props) => {
  // 提单标记
  const [mapIns, setmapIns] = useState(null);
  const [visible, setVisible] = useState(true);
  const [portInfo, setportInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [current, setCurrent] = useState(0);
  const [mapData, setmapData] = useState(null);
  const [mBill, setMbill] = useState();
  // const [worldId, setWorld] = useState(1);
  const [worldType, setWorldType] = useState("vec");
  const [worldFontType, setWorldFontType] = useState("cva");
  const [tipVO, settipVO] = useState({});
  const [isHiddenHead, setisHiddenHead] = useState(false);
  const [showDrawer, setshowDrawer] = useState(true);

  const [showVedioModal, setshowVedioModal] = useState(false);
  const [vedioInfo, setvedioInfo] = useState({});
  const [storeInfo, setstoreInfo] = useState({});

  useEffect(() => {
    document.title = "物流轨迹";
    initMap();
    // map_orders();
    shipTrackData(id);
    setisHiddenHead(sessionStorage.getItem("unheard") == "true");
    if (sessionStorage.getItem("hidebtn") != "true") {
    }
  }, [id]);
  useEffect(() => {
    const viewTime = new Date().getTime();
    const handleVisibilityChange = async () => {
      if (document.visibilityState === "visible") {
      } else if (document.visibilityState === "hidden") {
        await burialPoint("驾驶舱-物流跟踪详情", viewTime);
      }
    };
    handleVisibilityChange();
    document.addEventListener("visibilitychange", handleVisibilityChange);

    // 清理函数，组件卸载时移除事件监听器
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);
  // 初始化地图
  const initMap = () => {
    const map_options = {
      attributionControl: false,
      minZoom: 1,
      maxZomm: 10,
      zoomControl: false,
      //  crs: L.CRS.EPSG4326, // 天地图设置
    };

    map = L.map("map", map_options);
    map.setView([0, 180], 3);
    let zoomControl = L.control.zoom({ position: "topright" });
    map.addControl(zoomControl);
    let mapkey = "7b84c7681c437faefd8ada162fb4845e"; // 7b84c7681c437faefd8ada162fb4845e,6665ee9e5b6e8b7778f3fc0f4a1e9913
    if (String(window.location.href).includes("localhost")) {
      mapkey = "13aaaf7907b41c2e04034d430da1ce36";
      // mapkey = "d328e18e35757ded4d60b6b3977a7654";
    }
    setmapIns(map);
    const tdt_img = L.tileLayer(
      `https://t{s}.tianditu.gov.cn/vec_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=vec&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=${mapkey}`,
      {
        subdomains: [0, 1, 2, 3, 4, 5, 6, 7],
        transparent: true,
        //zoomOffset:1,
        zIndex: 3,
      }
    );
    const tdt_cia = L.tileLayer(
      `https://t{s}.tianditu.gov.cn/cva_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=cva&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TileMatrix={z}&TileCol={x}&TileRow={y}&tk=${mapkey}`,
      {
        subdomains: [0, 1, 2, 3, 4, 5, 6, 7],
        transparent: true,
        //zoomOffset:1,
        zIndex: 3,
      }
    );
    const img_w = L.tileLayer(
      `https://t{s}.tianditu.gov.cn/img_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TileMatrix={z}&TileCol={x}&TileRow={y}&tk=${mapkey}`,
      {
        subdomains: [0, 1, 2, 3, 4, 5, 6, 7],
        transparent: true,
        //zoomOffset:1,
        zIndex: 3,
      }
    );
    const cva_w = L.tileLayer(
      `https://t{s}.tianditu.gov.cn/cva_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=cva&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TileMatrix={z}&TileCol={x}&TileRow={y}&tk=${mapkey}`,
      {
        subdomains: [0, 1, 2, 3, 4, 5, 6, 7],
        transparent: true,
        //zoomOffset:1,
        zIndex: 3,
      }
    );
    const ter_w = L.tileLayer(
      `https://t{s}.tianditu.gov.cn/ter_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=ter&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TileMatrix={z}&TileCol={x}&TileRow={y}&tk=${mapkey}`,
      {
        subdomains: [0, 1, 2, 3, 4, 5, 6, 7],
        transparent: true,
        //zoomOffset:1,
        zIndex: 3,
      }
    );
    const cta_w = L.tileLayer(
      `https://t{s}.tianditu.gov.cn/cta_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=cta&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TileMatrix={z}&TileCol={x}&TileRow={y}&tk=${mapkey}`,
      {
        subdomains: [0, 1, 2, 3, 4, 5, 6, 7],
        transparent: true,
        //zoomOffset:1,
        zIndex: 3,
      }
    );
    const d1 = L.layerGroup([tdt_img, tdt_cia]);
    const d2 = L.layerGroup([img_w, cva_w]);
    const d3 = L.layerGroup([ter_w, cta_w]);
    const baseLayers = {
      地图: d1,
      影像: d2,
      地形: d3,
    };
    map.addLayer(d1);
    L.control.layers(baseLayers, null).addTo(map);
    setmapData(map);
    map.addEventListener("click", onlatWithlong);
    // debugger
  };
  // 靠港信息
  const getPortInfo = async (m) => {
    let now = dateFormat(new Date(), "yyyy-mm-dd HH:MM:ss");
    try {
      const {
        data: { list: portData, tipVO },
      } = await mainRecord({ code: sessionStorage.getItem("code") });
      settipVO(tipVO);
      if (portData.length > 0) {
        let arrPorts = [];
        portData.map((m) => {
          // 港口 优先中文其次英文

          m.portName = m?.title;

          // 国家
          m.countryName = m.countryCn || m?.contryEn;
          // 到港时间

          m.ata = m?.inTime ? moment(m?.inTime).format("YYYY-MM-DD") : "";
          // 离港时间

          m.atd = m?.outTime ? moment(m?.outTime).format("YYYY-MM-DD") : "";
          // 换船信息
          if (m.recordType === "TRANSHIPMENT_PORT") {
            m.nextShipNameEn = m.nextShipNameEn;
          }

          arrPorts.push(m);
          setCurrent(arrPorts.length - 1);
        });
        // debugger
        if (arrPorts.length > 0) {
          setportInfo(arrPorts);
        } else {
          setportInfo([]);
        }
      }
    } catch (error) {}
  };

  // 船舶轨迹
  const shipTrackData = async (m) => {
    try {
      // 绘制轨迹
      let lineList = [];

      console.log("=====id", id);
      let arrLineList = [];
      const { data, code } = await gettrajectory({
        code: sessionStorage.getItem("code"),
      });
      let coordinates = [];
      let coordinates1 = [];

      let timer = "";
      clearTimeout(timer);
      if (code == "2") {
        timer = setTimeout(() => {
          return shipTrackData(m);
        }, 3000);
        return false;
      }
      setLoading(false);
      arrLineList = data?.list;
      if (arrLineList) {
        if (arrLineList && arrLineList.length > 0) {
          let lineLength = arrLineList.length;
          let lines = [];

          let isThrow180 = arrLineList?.find(
            (f) => Math.ceil(f?.lon / 10000 / 60) < -178
          );

          coordinates = arrLineList.map((m) => {
            let l_lat = m.lat / 10000 / 60;
            let l_lon = m.lon / 10000 / 60;
            // console.log('lon---==', isThrow180);
            if (l_lon < 0 && isThrow180) {
              l_lon += 360;
            }
            if (l_lon > 170) {
              console.log(l_lon);
            }
            lines.push([l_lat, l_lon]);

            if (["departure", "port_start"].includes(m?.type)) {
              console.log("===-port_start----", l_lon);
              lineList.push(markerCritical(l_lat, l_lon, "start", m));
              map.setView([l_lat, l_lon], 3);
            }
            if (m?.type === "port_dest") {
              lineList.push(markerCritical(l_lat, l_lon, "dest", m));
            }
            if (m?.type === "port_transhipment") {
              lineList.push(markerCritical(l_lat, l_lon, "transhipment", m));
            }
            if (m?.type === "warehouse") {
              lineList.push(markerCritical(l_lat, l_lon, "warehouse", m));
            }
            return [l_lat, l_lon];
          });

          // 动态轨迹
          // dynamicTrajector(coordinates);
          // let track = new L.TrackPlayer(coordinates, {
          //     markerIcon: L.icon({
          //         iconUrl: boat,
          //         iconSize: [48, 48],
          //         // iconAnchor: [20, 22],
          //         className: 'travel_ship'
          //     }),
          //     markerRotation: true,
          //     weight: 10,

          //     speed: 99,
          // })
          //     .addTo(map);
          // track.start();

          lineList.push(
            L.polyline(lines, {
              weight: 4,
              color: "blue",
              dashArray: "0",
              zIndexOffset: -1,
            })
          );

          // 添加轨迹箭头
          let Line = L.polyline(lines, {
            weight: 1,
            zIndexOffset: -1,
          });
          let arrowLine = L.polylineDecorator(Line, {
            zIndexOffset: -1,
            billCode: id,
            lyType: "arrow",
            patterns: [
              {
                offset: 60,
                repeat: 65,
                symbol: L.Symbol.arrowHead({
                  pixelSize: 10,
                  zIndexOffset: -1,
                  pathOptions: {
                    zIndexOffset: -1,
                    fillOpacity: 2,
                    color: "blue",
                    weight: 0,
                  },
                }),
              },
            ],
          });
          //  有轨迹的时候 执行画线和箭头
          if (arrLineList.find((f) => f?.type == "ship")) {
            lineList.push(arrowLine);
          }
          console.log("arrowLine", arrowLine);
          // debugger
          //如果最后一个点是 ship marker-->ship
          let icon_boat = L.icon({
            iconUrl: boat,
            iconSize: [48, 48],
            iconAnchor: [20, 22],
            className: "boat", //边框
          });

          if (arrLineList[lineLength - 1]?.type === "ship") {
            // debugger

            let { lat: lat1, lon: lon1, cog } = arrLineList[lineLength - 1];
            let { lat: lat2, lon: lon2 } = arrLineList[lineLength - 2];

            console.log(lat1, lat2, "arrLineList[lineLength - 1]", cog);
            const shipMarker = L.marker(lines[lineLength - 1], {
              icon: icon_boat,
              rotationAngle: cog / 100 + 90, // 旋转角度
              zIndexOffset: 9999,
            });

            lineList.push(shipMarker);
          }
          lineGroup_line = L.layerGroup(lineList).addTo(map);
          // 设置箭头图标的方向

          setLoading(false);
          // 添加圆点
          cirMarker(lines, 10, arrLineList, m);
        } else {
          setLoading(false);
          message.error("当前提单暂无历史轨迹数据");
          setportInfo([]);
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  // "中转，起点，终点，仓库标记"
  const markerCritical = (l_lat, l_lon, type, m) => {
    let iconUrl = startIcon;
    let time = getTime(m?.posTime);
    let tips = `<div class="tips_container">
                 <div class="tips_port">${m?.title}</div>`;
    let content = `<div class="tips_content">离港: ${time}</div>`;
    if (type === "transhipment") {
      iconUrl = guoIcon;

      content = `<div class="tips_content">离港: ${time}</div>`;
    }
    if (type === "dest") {
      iconUrl = endIcon;

      content = `<div class="tips_content">到港： ${time}</div>`;
    }
    if (type === "warehouse") {
      iconUrl = cangkuIcon;

      content = `<div class="tips_content">到仓： ${time}</div>`;
    }
    tips += content;
    tips += "</div>";
    let icon = L.icon({
      iconUrl: iconUrl,
      iconSize: [36, 45],
      iconAnchor: [18, 40],
    });
    let marker = L.marker([l_lat, l_lon], {
      zIndexOffset: 2,
      icon: icon,
      billCode: id,
      lyType: "startPoint",
    });

    marker = L.marker([l_lat, l_lon], {
      zIndexOffset: 2,
      icon: icon,
      popupAnchor: [90, 90],
      billCode: id,
      lyType: "startPoint",
    })
      .bindPopup(tips, {
        className: "mypopup", //(i & 1) == 0 ? 'mypopup' : 'mypopup1',
        closeButton: false,
        autoClose: false,
        closeOnClick: false,
        closePopupOnClick: false,
        offset: [9, 9],
      })
      .on("mouseover", function () {
        this.openPopup();
      })
      .on("mouseout", function () {
        this.closePopup();
      });
    return marker;
  };

  // 自定义地图轨迹圆点
  const cirMarker = (arr, len, data, m) => {
    let arrLen = arr.length,
      distance = arrLen <= len ? 1 : Math.round((arrLen / len) * 100) / 100,
      targetArr = [],
      coordinates = [];
    // debugger
    for (let i = 0; Math.round(i) < arrLen - 1; i += distance) {
      let num = Math.round(i);
      targetArr.push({
        utc: data[num].posTime,
        points: arr[num],
      });
      coordinates.push(arr[num]);
    }

    //添加最后一个点
    targetArr.push({
      utc: data[arrLen - 1].posTime,
      points: arr[arrLen - 1],
    });

    let circleList = [];
    let point_circle = L.icon({
      iconUrl: circleIcon,
      iconSize: [14, 14],
    });

    targetArr.map((item, i) => {
      if (item.utc) {
        let circle = L.marker(item.points, {
          icon: point_circle,
          billCode: m,
          lyType: `circleMarker${i}`,
        });
        circle
          .bindPopup(
            `<span style="padding:4px 8px">${getTime(item.utc)}</span>`,
            {
              className: "mypopup1", //(i & 1) == 0 ? 'mypopup' : 'mypopup1',
              closeButton: false,
              autoClose: false,
              closeOnClick: false,
              closePopupOnClick: false,
            }
          )
          .on("mouseover", function () {
            this.openPopup();
          })
          .on("mouseout", function () {
            this.closePopup();
          });
        addLayer_marker(circle, ly_marker);
        circleList.push(circle);
      }
    });
    circleGroup_circle = L.layerGroup(circleList).addTo(map);

    // 添加用于显示和隐藏的图层
    addLayer_map(map, m, ly_track);
  };

  // 地图点击
  const onlatWithlong = (e) => {
    console.log("location:", e.latlng.lat, e.latlng.lng);
  };

  const onClose = () => {
    console.log(map);
    setVisible(false);
  };

  return (
    <>
      {/* 地图 */}
      <div>
        <Spin spinning={loading}>
          <div
            id="map"
            className={
              isHiddenHead ? "mapstyle_track unhdead" : "mapstyle_track"
            }
          ></div>
        </Spin>
      </div>

      {/* 地图sidebar */}
      <div>
        {showDrawer ? (
          <DetailDrawer
            setshowDrawer={setshowDrawer}
            visible={visible}
            isHiddenHead={isHiddenHead}
            tipVO={tipVO}
            portInfo={portInfo}
            id={id}
            current={current}
          />
        ) : (
          <div className="show_drawer_btn" onClick={() => setshowDrawer(true)}>
            展开 <img src={triangle1} />
          </div>
        )}
        {/* {showDrawer ? (getValueFromParamsUrl('typeDrawer') == "detail" ? <DetailDrawer
                    setshowDrawer={setshowDrawer}
                    visible={visible}
                    isHiddenHead={isHiddenHead}
                    tipVO={tipVO}
                    portInfo={portInfo}
                    id={id}
                    current={current} /> : <StorageDrawer
                    setshowDrawer={setshowDrawer}
                    visible={visible}
                    isHiddenHead={isHiddenHead}
                    tipVO={tipVO}
                    portInfo={portInfo}
                    id={id}
                    current={current}
                />) :
                    <div className="show_drawer_btn" onClick={() => setshowDrawer(true)}>
                        展开 <img src={triangle1} />
                    </div>} */}
      </div>

      {/* 实时监控 */}
      {showVedioModal && <OnlineVeidoModal {...vedioInfo} />}
    </>
  );
};

export default Track;
