import dateFormat from "dateformat"

const converToShipType = (type) => {
    let num = type || 0
    let str = ''
    switch (true) {
        case num <= 79 && num >= 70:
            str = '货船'
            break
        case num <= 89 && num >= 80:
            str = '油轮'
            break
        case num <= 89 && num >= 80:
            str = '油轮'
            break
        case num == 100:
            str = '集装箱'
            break
        default:
            break
    }
    return str
}


const getWeek = (time) => {
    var week = new Date(time).getDay()
    let str = ''
    if (week == 0) {
        str = "星期日"
    } else if (week === 1) {
        str = "星期一"
    } else if (week === 2) {
        str = "星期二"
    } else if (week === 3) {
        str = "星期三"
    } else if (week === 4) {
        str = "星期四"
    } else if (week === 5) {
        str = "星期五"
    } else if (week === 6) {
        str = "星期六"
    }
    return str
}


const getTime = (time) => {
    const ss = time * 1000
    let newData = new Date(ss)
    let d = dateFormat(newData, 'yyyy-mm-dd HH:MM:ss')
    return d
}

const dateFormat_ymd = (d, fmt) => {
    let o = {
        "M+": d.getMonth() + 1,                 //月份 
        "d+": d.getDate(),                    //日 
        "h+": d.getHours(),                   //小时 
        "m+": d.getMinutes(),                 //分 
        "s+": d.getSeconds(),                 //秒 
        "q+": Math.floor((d.getMonth() + 3) / 3), //季度 
        "S": d.getMilliseconds()             //毫秒 
    };
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (d.getFullYear() + "").substr(4 - RegExp.$1.length));
    }
    for (let k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        }
    }
    return fmt;
}

// 获取指定名称的cookie
const getCookie = (name) => {
    var strcookie = document.cookie//获取cookie字符串
    var arrcookie = strcookie.split("; ")//分割
    //遍历匹配
    for (var i = 0; i < arrcookie.length; i++) {
        var arr = arrcookie[i].split("=")
        if (arr[0] == name) {
            return arr[1]
        }
    }
    return ""
}

// 打印所有cookie
const printCookie = () => {
    var strcookie = document.cookie//获取cookie字符串
    var arrcookie = strcookie.split(";")//分割

    //遍历匹配
    for (var i = 0; i < arrcookie.length; i++) {
        var arr = arrcookie[i].split("=")
        // console.log(arr[0] + "：" + arr[1])
    }
}
// 用户角色
const userRole = () => {
    return localStorage.getItem("userRole")
}
// 用户id
const userId = () => {
    return localStorage.getItem("uid")
}
// 用户Name
const userName = () => {
    return localStorage.getItem("userName")
}

// 脱敏
const desensitization = (str, beginLen, endLen) => {
    var len = str.length;
    var firstStr = str.substr(0, beginLen);
    var lastStr = str.substr(endLen);
    var middleStr = str.substring(beginLen, len - Math.abs(endLen)).replace(/[\s\S]/ig, '*');
    let tempStr = firstStr + middleStr + lastStr;
    return tempStr;
}

const getParamsFromUrl = () => {
    let url = window.location.search;
    // debugger
    let obj = new Object();
    if (url.includes('?')) {
        let str = url.split('?')[1];
        // 如果是一个参数
        if (!str.includes("&")) {
            let arr = str.split("=");
            obj[arr[0]] = arr[1];
        } else {
            //  如果是多个参数
            let arr = str.split('&');
            arr.forEach(item => {
                let item_arr = item.split("=");
                obj[item_arr[0]] = item_arr[1]
            })
        }

    }
    return obj
}
const getValueFromParamsUrl = (key) => {
    console.log('getParamsFromUrl()?.[key]', getParamsFromUrl()?.[key]);
    return getParamsFromUrl()?.[key] || ""
}

const openNewTab = (url) => {
    var a = document.createElement("a");
    a.href = url;
    a.target = "_blank";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}
export { openNewTab, converToShipType, getWeek, getTime, printCookie, getCookie, desensitization, dateFormat_ymd, userRole, userId, userName, getParamsFromUrl, getValueFromParamsUrl }