import { Select, Drawer, Steps, Row, Col, message, Spin, Modal } from "antd";
import moment from "moment";
import "./leaflet.css";
import L, { marker } from "leaflet";
import "./Leaflet.PopupListLayer.js";
import "./blinkmarker";
import "./blinkmarker.scss";
import boat from "@/assets/chuan.svg";
import zz from "@/assets/zz.svg";
import gangkou from "@/assets/gangkou.svg";
import cangkuIcon from "@/assets/ck.svg";

import start_end_sport from "@/assets/lanhui.svg";
import start_end_sport_warehouse from "@/assets/start_end_sport_warehouse.svg";
import trackIcon from "@/assets/track.svg";
import storeIcon from "@/assets/store.svg";
import { useEffect, useLayoutEffect, useState } from "react";
import {
  getpositionList,
  gettrackInfo,
  gettrackInfoVisible,
  gettrackInfoWorkflow,
  getprogressList,
  getstoreList,
  getMonitorBind,
  burialPoint,
} from "@/api";
import "./index.scss";
import MapLeftInfo from "./mapComponents/mapLeftInfo";
import EnterpressMapLeftInfo from "./mapComponents/mapLeftInfo_enterpress";
import { v4 as uuid } from "uuid";
import OnlineVeidoModal from "@/components/OnlineVedioModal";
import StoreDrawer from "./mapComponents/storeDrawer.js";
import ProgressDrawer from "./mapComponents/progressDrawer.js";
import MarketRight from "./marketRight";
import { enumStatus, enumType } from "@/utils/enum";
import { openNewTab } from "@/utils/common";
var map = null;
// 位置 类型字典
const positionType = {
  port_start: "起运",
  warehouse: "入仓",
  ship: "在途",
  port_transhipment: "中转",
  port_dest: "到港",
};

// 闪烁标注图层（动画）
let markLinghtGroup = null;
// marker 图层
let ly_marker = null;
let mapLayer = null;
let blinkShipMarker = null;
let layer_remarkers = null;
let clearblinkMarker = null;
const offsetDrawer = 438;
let zoomLevel = 3;
let markersMap = [];
const Cloudvision = (props) => {
  // 提单标记
  const [mapIns, setmapIns] = useState(null);
  const [loading, setLoading] = useState(true);
  const [mapData, setmapData] = useState(null);
  const [mapMarkers, setmapMarkers] = useState([]);
  const [isModalOpen, setisModalOpen] = useState(false);
  const [customTipInfo, setcustomTipInfo] = useState({});
  const [mapLeftBtn, setmapLeftBtn] = useState(false);
  const [showprogress, setshowprogress] = useState(false);
  const [showstore, setshowstore] = useState(false);
  const [progressInfo, setprogressInfo] = useState([]);
  const [storeInfo, setstoreInfo] = useState({});
  const [drawerFreshkey, setdrawerFreshkey] = useState(uuid());
  const [freshItemKey, setfreshItemKey] = useState(uuid());
  const [freshListKey, setfreshListKey] = useState(uuid());
  const [freshEnterPress, setfreshEnterPress] = useState(uuid());
  const [activeItem, setactiveItem] = useState(null);
  const [itemInfo, setitemInfo] = useState("");
  const [showVedioModal, setshowVedioModal] = useState(false);
  const [vedioInfo, setvedioInfo] = useState({});
  const [computeProgressWidth, setcomputeProgressWidth] = useState(1);
  const [computeStoreWidth, setcomputeStoreWidth] = useState(1);
  const [computeDrawerBottom, setcomputeDrawerBottom] = useState(0);
  const [markerType, setmarkerType] = useState("");
  const [apiHeader, setapiHeader] = useState({});
  const [isHiddenHead, setisHiddenHead] = useState(false);
  const [isEnterPress, setisEnterPress] = useState(false);
  const [clickMarkerType, setclickMarkerType] = useState("");

  useEffect(() => {
    if (mapLayer) {
      mapLayer?.clearLayers();
    }

    clearblinkMarker?.clearLayers();
    createMap();
    setmapLeftBtn(sessionStorage.getItem("unbtn") == "true");
    if (sessionStorage.getItem("unheard") == "true") {
      setisHiddenHead(true);
      setcomputeDrawerBottom(50);
    }
    sessionStorage.removeItem("enterpress_key");
  }, []);
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        document.title = "驾驶舱";
        // alert("jrj");
      } else if (document.visibilityState === "hidden") {
        document.title = "离开";
        // alert("离开");
      }
    };
    handleVisibilityChange();
    document.addEventListener("visibilitychange", handleVisibilityChange);

    // 清理函数，组件卸载时移除事件监听器
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);
  useEffect(() => {
    if (sessionStorage.getItem("userRole") == "company_admin") {
      setisEnterPress(true);
    } else {
      setisEnterPress(false);
    }
  }, [sessionStorage.getItem("userRole")]);

  let computeClientWidth = (storeLists = 0) => {
    let getOffsetWidth = document.body.clientWidth - offsetDrawer;
    if (storeLists == 0) {
      setcomputeProgressWidth(getOffsetWidth - 232);
    } else {
      setcomputeProgressWidth(getOffsetWidth - 394);
    }
  };
  function createMap(mapId, mapOptions) {
    // 检查是否存在具有特定类名的地图容器
    var mapContainer = document.getElementById("map");
    if (
      mapContainer &&
      !L.DomUtil.hasClass(mapContainer, "leaflet-container")
    ) {
      // 如果没有找到leaflet-container类，说明没有地图实例，可以安全地创建新地图
      initMap();
    } else {
      // 如果已经存在地图实例，打印错误信息或进行其他处理
      console.error("A map already exists for the provided element.");
      // 返回null或现有地图实例，取决于你的需求
      return null;
    }
  }
  // 初始化地图
  const initMap = () => {
    const map_options = {
      attributionControl: false,
      minZoom: 1,
      maxZomm: 10,
      zoomControl: false,
      //  crs: L.CRS.EPSG4326, // 天地图设置
    };

    map = L.map("map", map_options);
    map.setView([0, 180], 3);
    let zoomControl = L.control.zoom({ position: "topright" });
    map.addControl(zoomControl);
    let mapkey = "7b84c7681c437faefd8ada162fb4845e"; // 7b84c7681c437faefd8ada162fb4845e,6665ee9e5b6e8b7778f3fc0f4a1e9913
    if (String(window.location.href).includes("localhost")) {
      mapkey = "13aaaf7907b41c2e04034d430da1ce36";
      // mapkey = "d328e18e35757ded4d60b6b3977a7654";
    }
    // const mapkey = "13aaaf7907b41c2e04034d430da1ce36";
    setmapIns(map);
    const tdt_img = L.tileLayer(
      `https://t{s}.tianditu.gov.cn/vec_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=vec&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=${mapkey}`,
      {
        subdomains: [0, 1, 2, 3, 4, 5, 6, 7],
        transparent: true,
        //zoomOffset:1,
        zIndex: 3,
      }
    );
    const tdt_cia = L.tileLayer(
      `https://t{s}.tianditu.gov.cn/cva_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=cva&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TileMatrix={z}&TileCol={x}&TileRow={y}&tk=${mapkey}`,
      {
        subdomains: [0, 1, 2, 3, 4, 5, 6, 7],
        transparent: true,
        //zoomOffset:1,
        zIndex: 3,
      }
    );

    const img_w = L.tileLayer(
      `https://t{s}.tianditu.gov.cn/img_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TileMatrix={z}&TileCol={x}&TileRow={y}&tk=${mapkey}`,
      {
        subdomains: [0, 1, 2, 3, 4, 5, 6, 7],
        transparent: true,
        //zoomOffset:1,
        zIndex: 3,
      }
    );
    const cva_w = L.tileLayer(
      `https://t{s}.tianditu.gov.cn/cva_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=cva&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TileMatrix={z}&TileCol={x}&TileRow={y}&tk=${mapkey}`,
      {
        subdomains: [0, 1, 2, 3, 4, 5, 6, 7],
        transparent: true,
        //zoomOffset:1,
        zIndex: 3,
      }
    );
    const ter_w = L.tileLayer(
      `https://t{s}.tianditu.gov.cn/ter_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=ter&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TileMatrix={z}&TileCol={x}&TileRow={y}&tk=${mapkey}`,
      {
        subdomains: [0, 1, 2, 3, 4, 5, 6, 7],
        transparent: true,
        //zoomOffset:1,
        zIndex: 3,
      }
    );
    const cta_w = L.tileLayer(
      `https://t{s}.tianditu.gov.cn/cta_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=cta&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TileMatrix={z}&TileCol={x}&TileRow={y}&tk=${mapkey}`,
      {
        subdomains: [0, 1, 2, 3, 4, 5, 6, 7],
        transparent: true,
        //zoomOffset:1,
        zIndex: 3,
      }
    );
    const d1 = L.layerGroup([tdt_img, tdt_cia]);
    const d2 = L.layerGroup([img_w, cva_w]);
    const d3 = L.layerGroup([ter_w, cta_w]);
    const baseLayers = {
      地图: d1,
      影像: d2,
      地形: d3,
    };
    map.addLayer(d1);
    L.control.layers(baseLayers, null).addTo(map);
    setmapData(map);
    map.addEventListener("click", onlatWithlong);

    initMapMarks();
  };

  // 地图点击
  const onlatWithlong = (e) => {
    setshowprogress(false);
    setshowstore(false);
    markLinghtGroup?.clearLayers();
    clickMarkerActiveItem({});
    clearblinkMarker?.clearLayers();
    initBlinkShip(markersMap);
  };
  const initBlinkShip = (list) => {
    if (list?.length == 0) {
      return false;
    }

    // 初始化船的波纹
    let blinkMarkers = removeRepeat(list)
      .filter((f) => f?.type == "ship")
      .map((m) => {
        let bill_lat = m.lat / 10000 / 60;
        let bill_long = m.lon / 10000 / 60;
        // debugger
        if (bill_long < -26) {
          bill_long = bill_long + 360;
        }
        return L.blinkMarker([bill_lat, bill_long], {
          iconSize: [25, 25],
          // zIndexOffset: 3,
          color: "transparent", // 主体颜色
          diveColor: "rgba(18, 90, 255,1)", // 发散颜色(red)
          level: "2", // 闪烁粗细
          speedTime: 2, // 发散闪烁的速度
        });
      });
    blinkShipMarker?.clearLayers();
    blinkShipMarker = L.layerGroup(blinkMarkers).addTo(map);
  };
  // 标注提单 ordersMarker
  const initMapMarks = async (type = "", item) => {
    type ? setfreshEnterPress(uuid()) : "";
    blinkShipMarker?.clearLayers();
    map.closePopup();
    // 清空地图 图层 / 隐藏业务进度 库存监控
    setshowprogress(false);
    setshowstore(false);
    mapLayer?.clearLayers();
    markLinghtGroup?.clearLayers();
    clearblinkMarker?.clearLayers();

    setmarkerType(type);
    setLoading(true);

    const { code, data, hasNextPage } = await getpositionList(
      type
        ? { pageNum: 1, pageSize: 200, type }
        : { pageNum: 1, pageSize: 200 },
      item
    );

    setLoading(false);
    if (hasNextPage) {
      initMapMarks(type);
    }
    let list = data?.list || [];
    setmapMarkers(list);
    initBlinkShip(list);
    markersMap = list;
    let markers = removeRepeat(list)
      .reverse()
      .map((m) => {
        // let markers = [{
        //   code: "MEDUBG124028",
        //   lat: 18040359, lon: 72041490,
        //   posTime: 1681627200,
        //   status: null,
        //   title: "富阳东洲港",
        //   traffic: "FYDZG",
        //   type: "port_dest",
        //   typeMain: "port"
        // }].map(m => {
        let bill_lat = m.lat / 10000 / 60;
        let bill_long = m.lon / 10000 / 60;
        // debugger
        if (bill_long < -26) {
          bill_long = bill_long + 360;
        }

        return L.marker([bill_lat, bill_long], {
          rotationAngle: m?.cog / 100 + 90,
          zIndexOffset: 1,
          icon: getIcon(m, bill_lat, bill_long),
        }).addEventListener("click", async (e) => {
          reMarker(bill_lat, bill_long, { ...m, markerType: type, list }, true);
        });
      });
    if (ly_marker) {
      ly_marker.clearLayers();
    }
    mapLayer = ly_marker = L.layerGroup(markers);
    // debugger
    map.addLayer(ly_marker);
    moveMapCentertoMoreMarkesPosition(list)
      ? map.setView(moveMapCentertoMoreMarkesPosition(list))
      : "";
    // map.setLatLng(markers[0]?.['_latlng']?.LatLng)
    // map.locate({ setView: true })
    return list;
    try {
    } catch (error) {
      console.log("地图散点", error);
    }
    return 9;
  };
  const removeRepeat = (list) => {
    // 过滤重复的 marker
    let a = [];
    list.forEach((f) => {
      let check = a.every((f2) => {
        return f?.lat != f2.lat && f.lon != f2.lon;
      });
      check ? a.push(f) : "";
    });
    return a;
  };
  // 移动地图中心点 到最多makers位置
  const moveMapCentertoMoreMarkesPosition = (arr) => {
    // 统计区域 lat 上下10 lon 左右10
    let markers = arr.map((m) => {
      let bill_lat = m.lat / 10000 / 60;
      let bill_long = m.lon / 10000 / 60;
      // debugger
      if (bill_long < -26) {
        bill_long = bill_long + 360;
      }
      return { lat: bill_lat, lon: bill_long };
    });
    //  第一步： 统计区域内的个数
    //  第二步：找出区域内最多的marker
    let max = 0;
    let mostMarkers = { lat: 0, lon: 180, sum: 0 };
    markers.forEach((m) => {
      let inAreaSum = 0;
      markers.forEach((f) => {
        let isInLat = f?.lat < m?.lat + 10 && f?.lat > m?.lat - 10;
        let isInLon = f?.lon < m?.lon + 10 && f?.lon > m?.lon - 10;
        if (isInLat && isInLon) {
          inAreaSum += 1;
        }
      });
      if (inAreaSum > max) {
        max = inAreaSum;
        mostMarkers = { ...m, sum: inAreaSum };
      }
    });

    return mostMarkers?.sum ? [mostMarkers?.lat, mostMarkers?.lon] : false;
  };
  //
  const activeLeftListOpenProgressAndStoreDrawer = async (m) => {
    // debugger
    const ladingCode = m?.ladingCode || "";
    const { billCode = "", orderCode = "" } = m?.extas || {
      billCode: "",
      orderCode: "",
    };
    try {
      const { data: progresData } = await getprogressList(
        { ladingCode, billCode, orderCode },
        apiHeader
      );

      const { data } = await getstoreList({ ladingCode }, apiHeader); //: 'TD202307201'
      setdrawerFreshkey(uuid());
      setshowprogress(true);
      setprogressInfo(progresData);

      setstoreInfo(data);
      setshowstore(true);
      computeClientWidth(data?.applyList?.length);
    } catch (error) {}
  };
  const openProgressAndStoreDrawer = async (m) => {
    // debugger
    const { ladingCode = "" } = m?.ladingCode ? m : { ladingCode: m?.code };
    const { billCode = "", orderCode = "" } = m?.extas || {
      billCode: "",
      orderCode: "",
    };
    try {
      const { data: progresData } = await getprogressList(
        { ladingCode, billCode, orderCode },
        apiHeader
      );

      const { data } = await getstoreList({ ladingCode }, apiHeader); //: 'TD202307201'
      setdrawerFreshkey(uuid());
      setshowprogress(true);
      setprogressInfo(progresData);

      setstoreInfo(data);
      setshowstore(true);
      computeClientWidth(data?.applyList?.length);
    } catch (error) {}
  };
  const handleBurial = async (m) => {
    console.log("handleBurial", m);

    await burialPoint("", new Date().getTime(), 1, {
      pageName: "驾驶舱-点位",
      name: "billClick",
      attr: {
        bill: m?.code,
        status: positionType[m?.type],
        location: m?.type == "ship" ? m?.lat + "," + m?.lon : m?.title,
      },
    });
  };
  //  重新渲染marker 获取远程数据后展示弹窗
  const reMarker = async (bill_lat, bill_long, m, ishandleMarker) => {
    setclickMarkerType(m?.type);
    clearblinkMarker?.clearLayers();
    blinkShipMarker?.clearLayers();
    // 获取监控 进度数据
    setprogressInfo({ nodeList: [] });
    setstoreInfo({ applyList: [] });
    let api = gettrackInfo;
    // 非协作
    // debugger
    if (m?.markerType == "visible") {
      api = gettrackInfoVisible;
    }
    // 协作
    if (m?.markerType == "workflow") {
      api = gettrackInfoWorkflow;
    }
    const { data: billList } = await api(
      { type: m?.type, traffic: m?.traffic },
      apiHeader
    );
    const hz = () => {
      var rootDom = document.createElement("div");
      rootDom.className = "popup_container";
      if (!billList || billList?.length == 0) {
        return false;
        // rootDom.innerHTML = ' <p style="width:56px"> 暂无数据</p>'
      } else if (billList.length === 1) {
        handleBurial(m);
        rootDom.innerHTML = renderMarkerTips(billList[0], m?.type);
        let track_viewing = rootDom.querySelector(`#track_viewing`);
        if (track_viewing) {
          track_viewing.onclick = function () {
            openDrawer(billList[0]?.code);
            // window.open(
            //     `/visible/track?code=${billList[0]?.code}&hidebtn=${true}&typeDrawer=detail`,
            //     "_blank"
            // );
          };
        }

        let realtime_montoring = rootDom.querySelector(`#realtime_montoring`);
        if (realtime_montoring) {
          realtime_montoring.onclick = function () {
            handleVedio(billList[0]?.code);
            // window.open(
            //     `/visible/track?code=${billList[0]?.code}&hidebtn=${true}&typeDrawer=storage`,
            //     "_blank"
            // );
          };
        }

        ishandleMarker ? handleMarker(billList[0]) : "";
        openProgressAndStoreDrawer(billList[0]);
      } else {
        // clickMarkerActiveItem({})
        setshowprogress(false);
        setshowstore(false);
        let arr = new Array(20).fill(1);
        rootDom.innerHTML = readerBillList(billList);
        let domItem = rootDom.querySelectorAll(".item_container");
        domItem.forEach((f) => {
          f.onclick = function () {
            let billCode = f.getAttribute("data-v");
            let customTip = billList.find((f) => f.code == billCode);

            setcustomTipInfo(customTip);
            console.log(customTip, "customTip", m, "m", billCode, "billCode");

            handleBurial({ ...customTip, title: m?.title, type: m?.type });
            setisModalOpen(true);
            handleMarker(customTip);
            openProgressAndStoreDrawer(customTip);
          };
        });
      }

      return rootDom;
    };
    layer_remarkers = L.popup({
      autoClose: true,
      closeButton: false,
      offset: [7, -9],
      className: "custome_popup",
      content: hz(),
    })
      .setLatLng([bill_lat, bill_long])
      .openOn(map);
    let blinkMarker = L.blinkMarker([bill_lat, bill_long], {
      iconSize: [25, 25],
      // zIndexOffset: 3,
      color: "transparent", // 主体颜色
      diveColor: "#1890ff", // 发散颜色(red)
      level: "5", // 闪烁粗细
      speedTime: 2, // 发散闪烁的速度
    });

    clearblinkMarker = L.layerGroup([blinkMarker]).addTo(map);
    // map.addLayer(layer_markers);
    map.setView([bill_lat, bill_long], map.getZoom());
    // marker.openPopup()
    /** 点击图标 */
  };
  // 实时监控
  const handleVedio = async (m) => {
    const {
      data: { monitorInfoBindDTOList = [], pic, mosaic = {} },
    } = await getMonitorBind(m, apiHeader);
    // 是否有绑定的相机
    if (!monitorInfoBindDTOList || monitorInfoBindDTOList?.length == 0) {
      return message.info("暂无监控视频");
    }
    if (monitorInfoBindDTOList?.length > 0) {
      setshowVedioModal(true);
      setvedioInfo({
        vid: uuid(),
        pic: pic?.url,
        mosaic: mosaic?.url,
        deviceChannelNo: monitorInfoBindDTOList[0]?.deviceChannelNo,
        deviceSerialId: monitorInfoBindDTOList[0]?.deviceSerialId,
      });
    }
    try {
    } catch (error) {
      console.log(data, "  ==--getMonitorBind--============");
    }
  };

  //展示 业务经度和仓储提货
  const showModalPogressAndStore = (m) => {
    setitemInfo(m?.code);
    setshowprogress(true);
    setshowstore(true);
    setdrawerFreshkey(uuid());
  };
  // 激活地图 marker对应的list item
  const clickMarkerActiveItem = (m) => {
    setfreshItemKey(uuid());
    setactiveItem(m);
  };
  const handleMarker = (m) => {
    showModalPogressAndStore(m);
    clickMarkerActiveItem(m);
  };
  // 根据点的图标 类型 getIcon

  const getIcon = (m, bill_lat, bill_long) => {
    let icon = L.icon({
      iconUrl: gangkou,
      iconSize: [40, 40],
      iconAnchor: [13, 20],
    });
    if (m?.type === "ship") {
      icon = L.icon({
        iconUrl: boat,
        iconSize: [44, 44],
        iconAnchor: [16, 28],
      });
    }
    if (m?.type === "warehouse") {
      icon = L.icon({
        iconUrl: cangkuIcon,
        iconSize: [40, 40],
        iconAnchor: [14, 22],
      });
    }
    if (m?.type === "port_transhipment") {
      icon = L.icon({
        iconUrl: zz,
        iconSize: [40, 40],
        iconAnchor: [13, 20],
      });
    }
    return icon;
  };
  //  点击marker 渲染订单列表
  const readerBillList = (arr) => {
    let listContainer = "<div class='list_container scrollbar_custom'>";
    let items = "";
    arr.map((m) => {
      let item = `<div class='item_container' data-v=${m?.code} key=${m?.code}>`;
      // let icon = `<img src=${tidan} />`;
      let title = `<div class='item_code'>${m?.code}</div>`;
      if (m?.shipType == "散货") {
        title = `<div> <span style="margin-right:6px">${
          m?.shipName || ""
        }</span><span>${m?.voyage || ""}</span></div>`;
      }

      item += title;
      item += "</div>";
      items += item;
    });
    listContainer += items;
    listContainer += "</div>";
    return listContainer;
  };
  const tags = (m) => {
    let tags = `<div class="tags">`;
    if (m?.shipType) {
      tags += `<span> ${m?.shipType || ""}</span>`;
    }
    if (m?.title) {
      tags += `<span> ${m?.title || ""}</span>`;
    }
    if (m?.number) {
      tags += `<span>${m?.number || ""}${m?.itemUnit || "吨"}</span>`;
    }
    if (m?.shipCompany) {
      tags += `<span>${m?.shipCompany || ""}</span>`;
    }
    if (m?.warehouseName) {
      tags += `<span>${m?.warehouseName || ""}</span>`;
    }
    tags += `</div>`;
    return tags;
  };
  const domFooter = (m, clickMarkerType) => {
    let footer = ``;
    if (
      [
        "port_transhipment",
        "ship",
        "port_dest",
        "departure",
        "port_start",
      ].includes(clickMarkerType)
    ) {
      footer += `<div div class="return_box"  > <a >${
        enumStatus[m?.pre?.status] || ""
      } </a><div class="circle_point_container"><i class="circle_point"></i></div><a> ${moment(
        m?.pre?.time
      ).format("YYYY-MM-DD")}</a></div>`;
      footer += `<dl id="track_viewing"  > <dt ><img width='16' src=${trackIcon} /></dt><dd>物流跟踪详情</dd></dl>`;
    }
    if (clickMarkerType == "warehouse") {
      footer += `<dl id="track_viewing"  > <dt ><img width='16' src=${trackIcon} /></dt><dd>物流跟踪详情</dd></dl>`;
      footer += `<dl id="realtime_montoring"><dt><img width='16' src=${storeIcon} /></dt><dd>货物实时监控</dd></dl>`;
    }

    footer += ``;
    return footer;
  };
  const domFooterCover = (m, clickMarkerType) => {
    if (
      [
        "port_transhipment",
        "ship",
        "port_dest",
        "departure",
        "port_start",
      ].includes(clickMarkerType)
    ) {
      return (
        <>
          <div className="return_box">
            {" "}
            <a>{enumStatus[m?.pre?.status]}</a>{" "}
            <div className="circle_point_container">
              <i className="circle_point"></i>
            </div>
            <a> {moment(m?.pre?.time).format("YYYY-MM-DD")}</a>
          </div>
          <dl onClick={goTranck}>
            {" "}
            <dt>
              <img width="16" src={trackIcon} />
            </dt>
            <dd> 物流跟踪详情</dd>
          </dl>
        </>
      );
    }
    if (clickMarkerType == "warehouse") {
      return (
        <>
          <dl onClick={goTranck}>
            {" "}
            <dt>
              <img width="16" src={trackIcon} />
            </dt>
            <dd> 物流跟踪详情</dd>
          </dl>

          <dl onClick={() => handleVedio(m?.code)}>
            <dt>
              <img width="16" src={storeIcon} />
            </dt>
            <dd> 货物实时监控</dd>
          </dl>
        </>
      );
    }
  };
  const goodsInfo = (m) => {
    const { currAmount = 0, profit = 0 } = m?.profit || {};
    const mount = parseInt(currAmount).toLocaleString();
    const pro = parseInt(profit).toLocaleString();
    // 供销社不展示货值信息
    if (m?.title == "泥炭") {
      return "";
    }
    if (!currAmount && !profit) return "";
    let dom = `<div class="fsb goods_info">`;
    if (currAmount) {
      dom += `<dl> <dt>${mount}</dt><dd> 货值</dd></dl>`;
    }
    if (profit > 0) {
      dom += `<dl> <dt style="color:red">+${pro}</dt><dd> 总盈亏</dd></dl>`;
    } else if (profit != 0) {
      dom += `<dl> <dt>${pro}</dt><dd> 总盈亏</dd></dl>`;
    }
    return (dom += "</div>");
  };
  const middleDom = (m, clickMarkerType) => {
    // debugger;
    let dom = `<div class="fsb">`;
    dom += `<div class="middle_left">
                <div class="left_icon"> 
                    <img width='20'  height="30" src=${
                      clickMarkerType == "warehouse"
                        ? start_end_sport_warehouse
                        : start_end_sport
                    } />
                </div> 
                 <div class="left_text"> 
                     <span>${m?.startPort || ""}</span>
                    <span>${m?.endPort || ""}</span>
                 </div>
            </div>`;
    //优先ata 再eta 展示

    dom += `<div class="middle_right">
         ${
           m?.atd
             ? `<dl><dt>ATD:</dt><dd>  ${
                 (m?.atd && moment(m?.atd).format("YYYY-MM-DD")) || "-"
               }</dd></dl>`
             : `<dl><dt>ETD:</dt><dd>  ${
                 (m?.etd && moment(m?.etd).format("YYYY-MM-DD")) || "-"
               }</dd></dl>`
         }
            ${
              m?.ata
                ? `<dl><dt>ATA:</dt><dd> ${
                    (m?.ata && moment(m?.ata).format("YYYY-MM-DD")) || "-"
                  }</dd></dl>`
                : `<dl><dt>ETA:</dt><dd> ${
                    (m?.eta && moment(m?.eta).format("YYYY-MM-DD")) || "-"
                  }</dd></dl>`
            }
           
                 </div>`;

    return (dom += `</div>`);
  };
  //  点击marker 渲染提单信息
  const renderMarkerTips = (m, clickMarkerType) => {
    let container = "<div class='marker_container'>";
    let header = "<div class='marker_header'>";
    let middle = "<div class='marker_middle'>";
    let footer = "<div class='marker_footer'>";
    header += `<div class=${
      m?.profit?.currAmount && m?.title != "泥炭" ? "bgdark" : " "
    }>`;
    header += `<div class="header_title" ><span class="header_title_text" style="font-size:14px"> ${
      m?.shipType == "散货"
        ? (m?.shipName || "") + " " + "  " + (m?.voyage || "")
        : m?.code
    } </span> <span> 
                ${
                  moment(m?.gmtCreate).format("YYYY-MM-DD") || " "
                }</span></div> `;

    header += goodsInfo(m);
    header += `</div>`;
    header += tags(m);
    middle += middleDom(m, clickMarkerType);
    footer += domFooter(m, clickMarkerType);

    header += "</div>";
    middle += "</div>";
    footer += "</div>";
    container += header;
    container += middle;
    container += footer;
    container += "</div>";
    return container;
  };

  // 点击drawer item 地图blink popup progress store 弹窗展现
  const handleDrawerItem = (item, list) => {
    markLinghtGroup?.clearLayers();
    clearblinkMarker?.clearLayers();
    //  地图上是否有 对应marker
    const m = mapMarkers?.find((f) => f?.code === item?.ladingCode);

    if (!m) {
      message.info("该业务暂无地图定位");
      map.closePopup();
      activeLeftListOpenProgressAndStoreDrawer(item);
      return false;
    }
    let bill_lat = m.lat / 10000 / 60;
    let bill_long = m.lon / 10000 / 60;
    if (bill_long < -26) {
      bill_long = bill_long + 360;
    }
    markLinghtGroup?.clearLayers();
    // markerBlink(bill_lat, bill_long);
    // showPopup(bill_lat, bill_long, { ...m, ...item })
    reMarker(bill_lat, bill_long, { ...m, markerType, list }, false);
  };

  const openDrawer = (code) => {
    openNewTab(
      `${
        window.location.origin
      }/visible/track?code=${code}&hidebtn=${true}&typeDrawer=detail`
    );
  };
  const goTranck = () => {
    openDrawer(customTipInfo?.code);
  };
  const computeRight = (info) => {
    if (!info) {
      return 232;
    }
    if (!info?.applyList || info?.applyList?.length == 0) {
      return 232;
    }
    return 394;
  };
  const handleSelect = (item) => {
    setfreshListKey(uuid());
    setapiHeader(item);
    initMapMarks("", item);
  };

  return (
    <>
      {/* 地图 */}
      <div className="cloudvison_container unhead">
        <Spin spinning={loading}>
          <div id="map" className="mapstyle"></div>
          <div className="map_left_container">
            {isEnterPress ? (
              <EnterpressMapLeftInfo
                handleSelect={handleSelect}
                freshListKey={freshListKey}
                apiHeader={apiHeader}
                setBlink={handleDrawerItem}
                freshItemKey={freshItemKey}
                activeItem={activeItem}
                initMapMarks={(type) => initMapMarks(type, apiHeader)}
              />
            ) : (
              <MapLeftInfo
                handleSelect={handleSelect}
                freshListKey={freshListKey}
                apiHeader={apiHeader}
                setBlink={handleDrawerItem}
                freshItemKey={freshItemKey}
                activeItem={activeItem}
                initMapMarks={(type) => initMapMarks(type, apiHeader)}
              />
            )}
          </div>
          <div className="map_right_container">
            <MarketRight />
          </div>
        </Spin>
      </div>

      <Modal
        open={isModalOpen}
        mask={false}
        closable={false}
        footer={null}
        onCancel={() => setisModalOpen(false)}
        width={338}
        bodyStyle={{ padding: 0 }}
      >
        <div style={{ position: "relative" }}>
          <div
            dangerouslySetInnerHTML={{
              __html: renderMarkerTips(customTipInfo, clickMarkerType),
            }}
          ></div>
          <div className="cover_footer_btn">
            {domFooterCover(customTipInfo, clickMarkerType)}
          </div>
        </div>
      </Modal>
      {/* 业务进度 */}
      {showprogress && (
        <ProgressDrawer
          dataInfo={progressInfo}
          contentWrapperStyle={{
            right: computeRight(storeInfo),
            width: computeProgressWidth,
          }}
          drawerFreshkey={drawerFreshkey}
          placement="bottom"
        />
      )}
      {/* 库存监控 */}
      {showstore && (
        <StoreDrawer
          storeInfo={storeInfo}
          handleVedio={handleVedio}
          drawerFreshkey={drawerFreshkey}
          contentWrapperStyle={{
            width: computeStoreWidth,
            bottom: computeDrawerBottom,
          }}
          placement="bottom"
        />
      )}

      {/* 实时监控 */}
      {showVedioModal && (
        <OnlineVeidoModal {...vedioInfo} warehouse={customTipInfo?.warehouse} />
      )}
    </>
  );
};

export default Cloudvision;
