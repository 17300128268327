import { unstable_HistoryRouter as HistoryRouter, Routes, Route } from 'react-router-dom'
import { history } from './utils'
import { LoadingOutlined } from '@ant-design/icons'
import { AuthComponent } from '@/components/AuthComponent'
import Cloudvision from './pages/Cloudvision'
import Track from './pages/Cloudvision/track'
import Notfound from './components/Notfound'
import { lazy, Suspense, useEffect } from 'react'
import './App.css'
import { getParamsFromUrl } from "./utils/common"


// 按需导入组件
const Login = lazy(() => import('./pages/Login'))
const Layout = lazy(() => import('./pages/Layout'))
const Home = lazy(() => import('./pages/Home'))
const SupplyTool = lazy(() => import('./pages/SupplyTool'))
const Outhenticatedflow = lazy(() => import('./pages/Outhenticatedflow'))
const User = lazy(() => import('./pages/User'))
const OrderForGoods = lazy(() => import('./pages/OrderForGoods'))
const Collaborate = lazy(() => import('./pages/Collaborate'))
const Warehouse = lazy(() => import('./pages/Warehouse'))
const HistoricalOrders = lazy(() => import('./pages/HistoricalOrders'))
// const DataOverview = lazy(() => import('./pages/DataOverview'))
// // import ShipDataOverview from "../SupplyDataOverview/index";
// const ShipDataOverview = lazy(() => import('./pages/ShipDataOverview'))
// const SupplyDataOverview = lazy(() => import('./pages/SupplyDataOverview'))
function App() {

    try {
        let pathurl = getParamsFromUrl();
        if (pathurl['token']) {
            localStorage.setItem('token', pathurl['token']);
            localStorage.setItem('accessToken', pathurl['token']);
        }
        // url 携带信息 存储sessionstorage中
        Object.keys(pathurl).forEach(f => {
            console.log(f);
            sessionStorage.setItem(f, pathurl[f]);
        })

    } catch (error) {
        console.log('app.js getParamsFromUrl', error);
    }

    // debugger
    return (
        // 路由配置
        <HistoryRouter history={history} basename='/visible' >
            <div className="App">
                <Suspense
                    fallback={
                        <div
                            style={{
                                textAlign: 'center',
                                marginTop: 200
                            }}
                        >
                            <LoadingOutlined style={{ color: 'gray' }} />
                            <span style={{ paddingLeft: '15px' }}>loading...</span>
                        </div>
                    }
                >
                    <Routes>
                        {/* 创建路由path和组件对应关系 */}
                        {/* Layout需要鉴权处理的 */}
                        <Route path='/' element={
                            // <AuthComponent>
                            <Layout />
                            // </AuthComponent>
                        }>
                            {/* 默认二级路由 */}
                            <Route index element={<Cloudvision />}></Route>
                            {/* 驾驶舱 */}
                            <Route path='cloudvision' element={<Cloudvision />}></Route>

                            {/* 我的雁群 */}
                            <Route index path='home' element={<Home />}></Route>
                            <Route path='supply-tool' element={<SupplyTool />} />
                            <Route path='track' element={<Track />}></Route>
                            {/* 个人中心 */}
                            <Route path='user' element={<User />}></Route>
                        </Route>
                        {/* 驾驶舱轨迹 没有header */}

                        <Route path='dashboard' element={<Cloudvision />}></Route>

                        {/* 外链 我的雁群 */}
                        {/* 非协作单,可视单设置 */}
                        <Route path='OrderForGoods' element={<OrderForGoods />}></Route>
                        {/* Collaborate 协作单 */}
                        <Route path='Collaborate' element={<Collaborate />}></Route>
                        {/* // 协作单 */}
                        <Route path='Warehouse' element={<Warehouse />}></Route>
                        {/* 历史订单 */}
                        <Route path='HistoricalOrders' element={<HistoricalOrders />}></Route>
                        {/* <Route path='DataOverview' element={<DataOverview />}></Route> */}
                        {/* <Route path='ShipDataOverview' element={<ShipDataOverview />}></Route>
            <Route path='SupplyDataOverview' element={<SupplyDataOverview />}></Route> */}
                        {/* 未找到 */}
                        <Route path='*' element={<Notfound />}></Route>
                        <Route path='login' element={<Login />}></Route>
                        <Route path='Outhenticatedflow' element={<Outhenticatedflow />}></Route>

                    </Routes>
                </Suspense>
            </div>
        </HistoryRouter>
    )
}

export default App
