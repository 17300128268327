import { useState, useEffect } from "react";
import { Select, Menu } from "antd"
import "./index.scss"
import { v4 as uuid } from "uuid";
import { getCompanyTree2 } from "@/api"
export default function Boss(props) {
    const [ItemsTree, setItemsTree] = useState([])
    const [openKeys, setopenKeys] = useState([]);
    const { handleSelect, handleEnterPressPerson } = props

    useEffect(() => {
        getCompany()
    }, [])
    const getCompany = async () => {
        try {
            const { data } = await getCompanyTree2();

            let items = data?.map(d1 => ({
                // label: <div onClick={handleItem}>{d1?.name}</div>,
                label: d1?.name,
                key: d1?.code,
                id: d1?.id,
                children: getChildren(d1, [].concat(d1?.employeeVOList).concat(d1?.sub).filter(f => f))
            }))
            items.unshift({
                label: "全部",
                key: -1,
            })
            setItemsTree(items)
        } catch (error) { }

    }
    const handleItem = (a) => {
        handleEnterPressPerson({ code: a?.userCode || a?.code, name: a?.userName || a?.name })
    }
    const getChildren = (d, c) => {
        let item = c?.map(d2 => ({
            label: <div onClick={() => handleItem(d2)}>{d2?.userName || d2?.name}</div>,
            key: d2.hasOwnProperty('sub') ? "全部" + (d?.code || d?.userCode) + "_" + (d2?.userCode || d2?.code) : (d?.code || d?.userCode) + "_" + (d2?.userCode || d2?.code),
            children: [].concat(d2?.employeeVOList).concat(d2?.sub).filter(f => f).length > 0 ? getChildren(d2, [].concat(d2?.employeeVOList).concat(d2?.sub).filter(f => f)) : null
        }))
        item.unshift({
            label: <div onClick={() => handleItem(d)}>{(d?.name || d?.userName) + "-全部"}</div>,
            key: "全部" + '_' + (d?.code || d?.userCode)
        })
        return item;
    }
    // 点击
    const onClick = (e) => {
        console.log(e, 'e==');
        // 点击全部 清空header 
        if (e.key == '-1') {
            return handleSelect({})
        }
        let key = e?.key.substr(e?.key.indexOf("_") + 1);

        if (e?.key.indexOf("全部") >= 0) {
            //   点击的是部门
            handleSelect({ department: key });
            return false;
        }
        // debugger
        handleSelect({ person: key })
    };
    const selectItem = (e) => {
        let currentkeyPath = e?.keyPath[0]
        if (e?.keyPath[0].indexOf('全部') != -1) {
            // currentkeyPath = currentkeyPath?.substr(2)
            // debugger
        }
        sessionStorage.setItem('enterpress_key', JSON.stringify(currentkeyPath))
    }

    const handleOpenChange = (e) => {
        sessionStorage.setItem('openkeys', JSON.stringify(e))
    }
    return (
        <>
            <Menu
                className="boss_select"
                onOpenChange={handleOpenChange}
                defaultOpenKeys={JSON.parse(sessionStorage.getItem('openkeys'))}
                onClick={onClick}
                defaultSelectedKeys={JSON.parse(sessionStorage.getItem('enterpress_key'))}
                onSelect={selectItem}
                mode="inline"
                items={ItemsTree}
            />
        </>
    )
}