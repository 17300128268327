export const nodeStatusVal = [
    { value: 'gateOutEmpty', label: '提箱' },
    { value: 'gateIn', label: '进场' },
    { value: 'loading', label: '装货' },
    { value: 'vesselDeparture', label: '开航' },
    { value: 'transport', label: '转运到港' },
    { value: 'unloadShip', label: '卸船' },
    { value: 'loadShip', label: '装船' },
    { value: 'changeShip', label: '换船' },
    { value: 'departure', label: '离港' },
    { value: 'arrivalAt', label: '抵港' },
    { value: 'discharge', label: '卸货' },
    { value: 'delivery', label: '提货' },
    { value: 'returnEmpty', label: '还箱' },
];
const getEnumFromList = function (list) {
    let obj = {};
    list.forEach(f => {
        obj = { ...obj, [f.value]: f.label }
    });
    return obj;
}
export const enumStatus = getEnumFromList(nodeStatusVal)

export const nodeShipTrack = [
    { value: 'departure', label: '起运地' },
    { value: 'port_start', label: '起运港' },
    { value: 'port_transhipment', label: '中转港' },
    { value: 'port_dest', label: '目的港' },
    { value: 'warehouse', label: '目的地' },
];
export const enumType = getEnumFromList(nodeShipTrack)