import { Select, Space, Divider, Input, Button } from "antd";
import { useState, useEffect, forwardRef, useImperativeHandle } from "react"
import './index.scss'
import searchIcon from "@/assets/search.svg"

const SearchHeader = forwardRef((props, ref) => {
  const [searchValue, setsearchValue] = useState('');

  const handleSearch = (e) => {

    console.log(e?.target?.value);
    props.getSelectValue(e?.target?.value)
    setsearchValue(e?.target?.value)
  }
  useImperativeHandle(ref, () => ({
    clearSearchValue() {
      console.log('useImperativehandle');
      setsearchValue('')
    }
  }))

  return (
    <div className={props?.className}>
      {props?.title ? <Space className="search_header" size={[10, 2]} align="center">
        <span className="title">{props?.title}</span>
        <Input prefix={<img src={searchIcon} />}
          className="search_input"
          placeholder="搜索"
          allowClear
          value={searchValue}
          onChange={handleSearch}
        />
      </Space> : <Input prefix={<img src={searchIcon} />}
        className="search_input"
        placeholder="搜索"
        allowClear
        value={searchValue}
        onChange={handleSearch}
      />}

    </div>
  )
})
export default SearchHeader