import { useState, useEffect } from "react";
import { Drawer, Space, Descriptions, Divider, Row, Col, Steps } from "antd";
import { CloseOutlined } from "@ant-design/icons"
import triangle2 from "@/assets/triangle2.svg";
import arrow from "@/assets/arrowLeft.svg";
import moment from "moment"
import { trackSteps } from "@/api"
import { enumStatus, enumType } from "@/utils/enum"
import py from "@/assets/qy.svg"
import ps from "@/assets/qs.svg"
import circle_point_fill_blue from "@/assets/circle_point_fill_blue.png"
import yuji from "@/assets/yuji.svg"
import { getValueFromParamsUrl } from "@/utils/common"
import "./index.scss"
const { Step } = Steps;
function DetailDrawer({ isHiddenHead, onClose, visible, id, current, setshowDrawer, isShowcloseBtn = false, marginLeft = 0 }) {
    const [portInfo, setportInfo] = useState([]);
    const [tipVO, settipVO] = useState({});
    const [pre, setpre] = useState({});
    useEffect(() => {
        getTrackSteps(getValueFromParamsUrl('code'));
    }, [getValueFromParamsUrl('code')])
    const getTrackSteps = async (code) => {
        const { data: { list, tipVO, pre } } = await trackSteps({ code });
        setportInfo(insertItemToCurrentItem(list));
        settipVO(tipVO)
        setpre(pre)
        console.log('list==', list);
    }
    const insertItemToCurrentItem = (list) => {
        let newList = [];
        list.forEach((f, i) => {
            f.typeCode = f.type;
            f.type = enumType[f.node];
            f.title = enumStatus[f?.status];
            f.isNode = false;
            if (f?.flag) {
                f = { ...f, isNode: true };
                newList.push(f)
            }
            if (f.typeCode != "warehouse") {
                f = { ...f, isNode: false };
                newList.push(f)
            }

        });
        console.log('newList==', newList);
        return newList;
    };
    // 根据 状态 展示不同icon 库存监控
    const getStepsIconStore = (m) => {

        if (m?.isNode) {
            if (m?.type == "起运地") {
                return <img width='20' src={py} />;
            }
            return <img width='20' src={ps} />;
        }
        return <img style={{ transform: 'translateX(2px)' }} width='6' src={circle_point_fill_blue} />;
    }
    const renderStep = () => {

        return portInfo?.map((m, index) => {
            let item = {

                icon: <div className={m?.isNode ? "node_icon_container" : "icon_container"} style={{ position: 'relative' }} >
                    {getStepsIconStore(m)}
                </div>,
                title:
                    <div
                        className="un_node_step"
                        style={{ display: 'flex', paddingLeft: 15, justifyContent: "space-between" }}
                    >
                        <span>
                            {m?.title}
                            {m?.delay > 0 && <span style={{ marginLeft: 10, color: "#F05656", fontSize: 8, backgroundColor: "rgba(240, 86, 86, .1)" }}>{enumStatus[m?.status]}延误{m?.delay}天</span>}
                        </span>
                        <span>{m?.estimatedTime && <img src={yuji} />} {m?.estimatedTime || m?.startTime}</span>
                    </div>,
                status: "finish"
            };
            if (m?.isNode) {
                item = {
                    ...item, title: <div className="node_step">
                        <span> {m?.type}</span>
                        <span>{m?.location}</span>
                    </div >
                }

            }
            if (!m?.isNode && ["loading", "changeShip", "loadShip"].includes(m?.status)) {
                item = {
                    ...item, description: <div className="step_description">
                        <div><span>船名</span> {m?.vessel}</div>
                        <div><span>航次</span> {m?.voyage}</div>
                        <div><span>MMSI</span> {m?.mmsi}</div>
                    </div >,
                    status: "finish"
                }

            }

            return item;
        })



    }

    return <>
        <Drawer
            className={`${isHiddenHead ? "map_drawer map_drawer_unhead" : "map_drawer"} ${isShowcloseBtn ? "compress_drawer_header" : ""}`}
            closable={false}
            zIndex={9999}
            title={
                <div className="custom_drawer_title">  <Space className="drawer_title" align="center" size={10}>
                    <span>{tipVO?.shipType == '散货' ? tipVO?.shipName + " " + "  " + tipVO?.voyage : tipVO?.code}</span>
                </Space><div onClick={() => setshowDrawer(false)} className="hide_drawer"> 收起 <img src={triangle2} /> </div>
                    {/* {isShowcloseBtn ? <div onClick={() => setshowDrawer(false)} style={{ fontWeight: 100, paddingRight: 10, color: "#fff", marginTop: 6 }}><CloseOutlined /></div> : <div onClick={() => setshowDrawer(false)} className="hide_drawer"> 收起 <img src={triangle2} /> </div>} */}
                </div>
            }
            mask={false}
            maskClosable={false}
            placement="left"
            width={370}
            style={{ marginTop: "60px", marginLeft: marginLeft + 15 }}
            getContainer={false}
            onClose={onClose}
            open={visible}
        >
            <div className="custom_drawer">
                {/* 提单信息 */}
                {/* 散货 */}
                <Descriptions title={<div ><Divider type="vertical" style={{ border: '1px solid #1C6CDC ' }} />提单信息</div>} >
                    {tipVO?.shipType !== '散货' && <Descriptions.Item label="提单号" span="3">{tipVO?.code}</Descriptions.Item>}
                    <Descriptions.Item label="形态" span="3">{tipVO?.shipType}</Descriptions.Item>
                    <Descriptions.Item label="品名" span="3">{tipVO?.title}</Descriptions.Item>

                    <Descriptions.Item label="仓库" span="2">{tipVO?.warehouse}</Descriptions.Item>
                    <Descriptions.Item label="数量" >{tipVO?.number} {tipVO?.itemUnit}</Descriptions.Item>
                    {tipVO?.shipType === '散货' && <Descriptions.Item span="2" label="船名">{tipVO?.shipName}</Descriptions.Item>}
                    {tipVO?.shipType === '散货' && <Descriptions.Item label="船次" span="2" >{tipVO?.voyage}</Descriptions.Item>}
                    <Descriptions.Item label="船公司" >{tipVO?.shipCompany}</Descriptions.Item>
                </Descriptions>
                <Divider dashed={true} />
                {/* 航次 */}
                <div className="drawer_sea">
                    <Row>
                        <Col span={11}>
                            <div className="font14">{tipVO?.startPort}</div>
                            {(tipVO?.etd || tipVO?.atd) &&
                                <div className="font12">
                                    {tipVO?.atd ? "ATD" : "ETD"}: {moment(tipVO?.atd || tipVO?.etd).format("YYYY-MM-DD")}
                                </div>
                            }
                        </Col>
                        <Col span={2}>
                            {(tipVO?.startPort || tipVO?.endPort) && <img src={arrow} />}
                        </Col>
                        <Col span={11} style={{ textAlign: "right" }}>
                            <div className="font14">{tipVO?.endPort}</div>
                            {(tipVO?.ata || tipVO?.eta) && <div className="font12">
                                {tipVO?.ata ? "ATA" : "ETA"}：{moment(tipVO?.ata || tipVO?.eta).format("YYYY-MM-DD")}
                            </div>}
                        </Col>
                    </Row>
                </div>

                {/* 靠港信息 */}
                <div style={{ padding: "16px 0" }} className="flex_sb">
                    <div>
                        <Divider type="vertical" style={{ border: '1px solid #1C6CDC ' }} />
                        <span className="leftSide_ship_title" style={{ fontWeight: 600 }}>物流跟踪详情</span>
                    </div>
                    <div className="detail_steps_title">
                        {enumStatus[pre?.status]} <i></i> {pre?.time}
                    </div>
                </div>
                <div>
                    <Steps

                        className="detail_steps"
                        items={renderStep(portInfo)}
                        direction="vertical">

                    </Steps>

                </div>
                {/* //盈亏信息 */}
                {tipVO?.title !== "泥炭" && <div className="detail_goods_info">
                    <Row className="fsb">
                        <Col span="12"> <span>仓库单号</span><span> {tipVO?.stockCode || "-"}</span></Col>
                        <Col span="12"> <span>库号</span><span> {tipVO?.stockNum || '-'}</span></Col>
                    </Row>
                    <Row className="fsb">
                        <Col span="12"> <span>货值</span><span> {tipVO?.profit?.currAmount && parseInt(tipVO?.profit?.currAmount).toLocaleString() || "-"}</span></Col>
                        <Col span="12">
                            <span>盈亏</span>
                            <span
                                style={{ color: tipVO?.profit?.profit > 0 ? 'red' : "rgba(42, 192, 97, 1)" }}>
                                &nbsp;{tipVO?.profit?.profit > 0 ? "+" : ""}
                                {tipVO?.profit?.profit && parseInt(tipVO?.profit?.profit || 0).toLocaleString() || <span>-</span>}
                            </span>
                        </Col>
                    </Row>

                </div>}
            </div>
        </Drawer>
    </>
}
export default DetailDrawer;