import { useEffect, useLayoutEffect, useState } from "react";
import { Divider, message, Popover } from 'antd';
import retractIcon from '../../assets/retractIcon.png'
import expandIcon from '../../assets/expandIcon.png'
import EmptyIcon from '../../assets/EmptyIcon.png'
import plusIcon from '../../assets/plusIcon.png'
import minusIcon from '../../assets/minusIcon.png'
import { priceUser, priceUserPost, priceUserSetting } from '@/api'
// priceUserSetting  EmptyIcon  plusIcon minusIcon
import { MinusCircleFilled, PlusCircleFilled, } from '@ant-design/icons';
import "./marketRight.scss";
const marketRight = () => {
    const [marketIcon, setMarketIcon] = useState(false)
    const [edit, setEdit] = useState(false)
    const [settingList, setSettingList] = useState([])
    const [priceUserList, setPriceUserList] = useState([])
    useEffect(() => {
        priceUserData()
        priceUserSettingData()
    }, [])
    const priceUserData = async () => {
        let res = await priceUser()
        setPriceUserList(res.data || [])
    }
    const priceUserSettingData = async () => {
        let res = await priceUserSetting()
        setSettingList(res.data)
    }
    const onClickMinus = (item, index) => {
        if (item?.select == true) {
            const newList = [...settingList]
            newList.map((item1, index) => {
                if (item?.code == item1?.code) {
                    item1.select = false
                    setSettingList(newList)
                }
            })
        }
    }

    const onClickprice = (item, index) => {
        if (item?.select != true) {
            const newList = [...settingList]
            newList.map((item1, index) => {
                if (item?.code == item1?.code) {
                    item1.select = true
                    setSettingList(newList)
                }
            })
        }
    }

    const onClickDetermine = async () => {

        let arr = []
        settingList.map((item, index) => {
            if (item?.select == true) {
                arr.push(item.code)
            } else {
                return
            }
        })
        let res = await priceUserPost(arr)
        if (res.code == '0000') {
            // setMarketIcon(false)
            priceUserData()
            priceUserSettingData()
            message.success("行情指数添加成功");
        }
    }
    const cancellation = () => {
        priceUserData()
        priceUserSettingData()
    }

    const onClickMarket = () => {
        setMarketIcon(!marketIcon)
    }
    return <div className='marketRight' style={{ borderRadius: marketIcon ? '4px 4px 0px 0px': '4px' }}>
        <div className='marketTop'>
            <div className='market' onClick={onClickMarket}>
                行情指数
                {
                    marketIcon ? (<img src={expandIcon} alt="" className='marketTopIcon' />) : (<img src={retractIcon} alt="" className='marketTopIcon' />)
                }

            </div>
            <div className='rightEdit' onClick={() => {
                setEdit(!edit)
                setMarketIcon(true)
            }}>
                {
                    edit ? (<>
                        <span onClick={onClickDetermine}>确定</span>
                        <span style={{ marginLeft: '30px' }} onClick={cancellation}>取消</span>
                    </>) : (<span>编辑</span>)
                }
            </div>
        </div>
        {
            (marketIcon) ? (<div className='marketConten' >
                {
                    (priceUserList.length == 0 && !edit) ? (<div className='EmptyBox'>

                        <div>
                            <img src={EmptyIcon} alt="" className='EmptyIconBox' />
                            <div className='EmptyFontBox'>
                                暂无关注行情指数
                            </div>
                        </div>
                    </div>) : (<>
                        {
                            edit ? (<>

                                {
                                    settingList?.map((item, index) => {
                                        return (
                                            <div key={index}>

                                                {item.select && (
                                                    <div className='indexBox' >
                                                        <div className='indexBoxLeft'>
                                                            <Popover title={item?.priceName} >
                                                                {item?.priceName}
                                                            </Popover>
                                                        </div>
                                                        <img src={minusIcon} alt="" onClick={() => onClickMinus(item, index)} className='minusIconClass' />
                                                        {/* <MinusCircleFilled style={{ lineHeight: '36px' }} /> */}
                                                    </div>

                                                )
                                                }
                                            </div>
                                        )
                                    }
                                    )
                                }
                            </>) : (<>
                                {
                                    priceUserList?.map((item, index) => {
                                        return (
                                            <div className='indexBox' key={index}>
                                                <div className='indexBoxLeft'>
                                                    <Popover  title={item?.priceName}>
                                                        {item?.priceName}
                                                    </Popover>
                                                </div>
                                                <div className='indexBoxRight'>
                                                    {
                                                        item?.change > 0 && (<span style={{ color: '#F73231' }}>
                                                            {item?.currentPrice} ( +{item?.change} {
                                                                item?.change ? '%' : ''
                                                            } )
                                                        </span>)
                                                    }
                                                    {
                                                        item?.change < 0 && (<span style={{ color: '#0EBF98' }}>
                                                            {item?.currentPrice} ( {item?.change} {
                                                                item?.change ? '%' : ''
                                                            } )
                                                        </span>)
                                                    }

                                                    {
                                                        item?.change == 0 && (<span style={{ color: '#000000' }}>
                                                            {item?.currentPrice} ( {item?.change}% )
                                                            
                                                        </span>)
                                                    }

                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </>)
                        }

                    </>

                    )
                }



                {
                    edit ? (<>
                        <Divider plain>其他指数</Divider>
                        {
                            settingList?.map((item, index) => {

                                return (
                                    <div key={index}>

                                        {
                                            !item.select && (
                                                <div className='indexBox'>
                                                    <div className='indexBoxLeft'>
                                                        <Popover title={item?.priceName} >
                                                            {item?.priceName}
                                                            </Popover>
                                                    </div>
                                                    <img src={plusIcon} alt="" onClick={() => onClickprice(item, index)} className='minusIconClass' />
                                                    {/* <PlusCircleFilled style={{ lineHeight: '36px', }} /> */}
                                                </div>
                                            )
                                        }
                                    </div>
                                )
                            })
                        }




                    </>) : (<></>)
                }


            </div>) : (<>
                {/* <img src={EmptyIcon} alt="" /> */}

            </>)
        }

    </div>;
}

export default marketRight;