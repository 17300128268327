import { Upload, Tabs, Row, Spin, Col, ConfigProvider, DatePicker, Modal, Button, List, message } from 'antd'
import { UploadOutlined, DoubleRightOutlined, WarningOutlined } from '@ant-design/icons'
import { useEffect, useImperativeHandle, useState } from 'react';

import EZUIKit from 'ezuikit-js';
import moment from 'moment';
import 'moment/locale/zh-cn';
import './index.scss'
moment.locale('zh-cn')
const OnlineVedio = (props) => {

    const { url, accessToken } = props
    const [playerClass, setPlayerClass] = useState(null)
    useEffect(() => {

        realTimeCamera(); // 实时摄像头
        // 组件销毁时  再次停止视频流
        return () => {
            try {

                playerClass && playerClass.stop()
                // document.getElementById('video-container').innerHTML = ""
            } catch (error) {
                console.log(error)
            }
        }
    }, [accessToken])
    useEffect(() => {
        if (url && playerClass) {

            changeUrl(); // 实时摄像头
        }
        // 组件销毁时  再次停止视频流
        return () => {
            try {

                playerClass && playerClass.stop()
                // document.getElementById('video-container').innerHTML = ""
            } catch (error) {
                console.log(error)
            }
        }
    }, [url])

    const changeUrl = () => {
        try {

            playerClass.stop().then(() => {
                playerClass.play(url)
            })
        } catch (error) {
            console.log(error, 'changeUrl')
        }

    }
    const realTimeCamera = () => {
        // 实例存在时
        if (url && playerClass) {
            try {
                playerClass.stop().then(() => {
                    playerClass.play(url)
                })
                // document.getElementById('video-container').innerHTML = ""
            } catch (error) {
                console.log(error, 'changeUrl')
            }

            return false;
        }
        const player = new EZUIKit.EZUIKitPlayer({
            id: 'video-container', // 视频容器ID
            accessToken: accessToken,
            url: url,
            width: props?.width || 970,
            height: props?.height || 500,
            template: 'security',//standard
            // header: ['zoom'],
            footer: [],
            useHardDev: true,
            handleSuccess: handleSuccess
        });

        player.play();
        setPlayerClass(player)
    };
    // 关闭弹窗是 停止
    useImperativeHandle(props.onRef, () => {
        return {
            stopVedio: stopVedio,
            stopVedioCurrent: stopVedioCurrent,
            pause: function () {
                playerClass.pause();
                console.log('pause');
            },
            resume: function () {
                playerClass.resume();
                console.log('pause');
            },
        }
    })
    const handleSuccess = () => {
        props.setIsplaying(true)
        // alert('success')
    }
    const stopVedio = () => {
        console.log('stop vedio');
        try {

            playerClass && playerClass.stop()
            document.getElementById('video-container').innerHTML = ""

        } catch (error) {
            console.log('stopVedio', error);
        }
    }
    const stopVedioCurrent = () => {
        console.log("stopVedioCurrent")
        try {

            playerClass && playerClass.stop().then(() => {
                playerClass.play(url)
            })
            // document.getElementById('video-container').innerHTML = ""

        } catch (error) {
            console.log('stopVedio', error);
        }
    }
    return (

        <div className="online_vedio" >
            <div id="video-container" />
        </div>
    )
}

export default OnlineVedio