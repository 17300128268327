import { useState, useRef, useEffect } from "react";
import { Drawer, Space, Descriptions, Divider, message, Col, Steps, Modal } from "antd";
import triangle2 from "@/assets/triangle2.svg";
import OnlineVedio from "@/components/OnlineVedio"
import OnlineVedio1 from "@/components/OnlineVedio"
import moment from "moment"
import { getMonitorBind, OnlineVedioApi, getstoreList } from "@/api"
import steps_progress from "@/assets/quan.svg";
import setps_wait from "@/assets/setps_wait.svg";
import trackIcon from "@/assets/track.svg"
import empty_store from "@/assets/empty_store.jpg"
// enlargeIcon
import enlargeIcon from "@/assets/enlargeIcon.png"
import zttdx from "@/assets/zttdx.png"
import DetailDrawer from "./detail";
const { Step } = Steps;

function StorageDrawer({ portInfo, tipVO, isHiddenHead, onClose, visible, id, current, setshowDrawer }) {
    const [vedioInfo, setvedioInfo] = useState({});
    const [vedioInfoOK, setVedioInfoOK] = useState({});
    const [storeInfo, setstoreInfo] = useState({});
    const [trackInfo, settrackInfo] = useState(false);
    const [pic, setpic] = useState({});
    let ChildRef_bank = useRef(null)
    let ChildRef_bank1 = useRef(null)
    const [isModalOpen, setIsModalOpen] = useState(false);
    useEffect(() => {
        handleVedio(tipVO?.code);
        initStorage(tipVO?.code);
    }, [tipVO?.code])
    const handleVedio = async (m) => {


        const { data: { monitorInfoBindDTOList = [], pic } } = await getMonitorBind(m);
        // 是否有绑定的相机
        if (!monitorInfoBindDTOList || monitorInfoBindDTOList?.length == 0) {
            // return message.info("暂无监控视频");
        }
        if (monitorInfoBindDTOList?.length > 0) {
            setpic(pic);
            const p = {
                pic: pic?.url,
                deviceChannelNo: monitorInfoBindDTOList[0]?.deviceChannelNo,
                deviceSerialId: monitorInfoBindDTOList[0]?.deviceSerialId

            }
            let { data } = await OnlineVedioApi(p);
            setvedioInfo(data || {})
        }
        try { } catch (error) {
            console.log(data, "  ==--getMonitorBind--============")
        }
    }
    const initStorage = async (code) => {
        const { data } = await getstoreList({ ladingCode: code });
        setstoreInfo(data)
    }
    // 仓库
    // 根据 状态 展示不同icon 库存监控
    const getStepsIconStore = (s) => {

        if (s === "finish") {
            return <img width='18' src={steps_progress} />;
        }
        return <img width='18' src={steps_progress} />;
    }
    // 提单状态
    const getStatus = (s, index) => {
        if (storeInfo?.applyList?.length - 1 == index) {
            return "waite";
        }
        if (s === "running") {
            return "process";
        }
        return "finish";
    };
    // 提单title
    const getTitle = (s) => {
        if (s === "input") {
            return <span style={{ color: '#0166FF' }}>入库</span>;
        }
        if (s === "waitoutput") {
            return <span style={{ color: 'rgba(0,0,0,0.4)' }}>提货</span>;
        }
        return <span style={{ color: '#0166FF' }}>提货</span>;
    };
    const getItem = (info) => {
        let lists = info?.applyList?.map((item, index) => (
            {
                title: getTitle(item?.kind),
                description: <span style={{ fontSize: 12, fontWeight: 400, lineHeight: "8px", color: 'rgba(0,0,0,0.4)' }}>{item?.gmtModify ? moment(item?.gmtModify).format("YYYY/MM/DD") : " "}</span>,
                icon: <div style={{ position: 'relative' }} className={index == storeInfo?.applyList?.length - 1 ? 'last_child' : ''}>
                    <a className="icon_quantity"> {item?.itemQuantity || ''} 吨</a>
                    <span className="steps_icon_wrap">

                        {getStepsIconStore(item?.status)}
                    </span>
                </div>,
                status: getStatus(item?.status, index),
            }
        )
        );
        if (info?.input - info?.outPut > 0) {
            lists.push({
                title: '提货',
                description: <span style={{ fontSize: 12, fontWeight: 400, lineHeight: "8px", color: 'rgba(0,0,0,0.4)' }}></span>,
                icon: <div style={{ lineHeight: '21.5px', position: 'relative' }} >

                    <span className="steps_icon_wrap">

                        <img width='18' src={setps_wait} />
                    </span>
                </div>,
                status: 'waite',
            })
        }
        return lists;
    }
    const handleVedioOk = async (m) => {

        const { data: { monitorInfoBindDTOList = [], pic } } = await getMonitorBind(m);
        // 是否有绑定的相机
        if (!monitorInfoBindDTOList || monitorInfoBindDTOList?.length == 0) {
            // return message.info("暂无监控视频");
        }
        if (monitorInfoBindDTOList?.length > 0) {
            setpic(pic);
            const p = {
                pic: pic?.url,
                deviceChannelNo: monitorInfoBindDTOList[0]?.deviceChannelNo,
                deviceSerialId: monitorInfoBindDTOList[0]?.deviceSerialId

            }
            let { data } = await OnlineVedioApi(p);
            setIsModalOpen(true);
            setVedioInfoOK(data || {})
        }
        try { } catch (error) {
            console.log(data, "  ==--getMonitorBind--============")
        }
        // ChildRef_bank.current.resume()
    }
    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const videowidth = 491;
    const videoHeight = 276;
    const vedioModWidth = 970;
    const vedioModHeight = 547;
    return <>
        <Drawer
            className={isHiddenHead ? "map_drawer map_drawer_unhead" : "track_drawer map_drawer"}
            closable={false}
            zIndex={9999}
            title={
                <div className="custom_drawer_title">  <Space className="drawer_title" align="center" size={10}>
                    <span>仓库详情</span>
                </Space>
                    <div onClick={() => setshowDrawer(false)} className="hide_drawer"> 收起 <img src={triangle2} /> </div>
                </div>
            }
            mask={false}
            maskClosable={false}
            placement="left"
            width={506}
            style={{ marginTop: "60px", marginLeft: 15 }}
            getContainer={false}
            onClose={onClose}
            open={visible}
        >
            <div className="custom_drawer">
                {/* 仓库信息 */}
                <Descriptions title={<div ><Divider type="vertical" style={{ border: '1px solid #1C6CDC ' }} />仓库信息</div>} >
                    <Descriptions.Item label="仓库" span="1">{tipVO?.warehouseName}</Descriptions.Item>
                    <Descriptions.Item label="库号" span="1">{tipVO?.stockArea}</Descriptions.Item>
                    <Descriptions.Item label="已堆存天数" span="1">{tipVO?.saveDays}</Descriptions.Item>
                    <Descriptions.Item label="仓单号" >{tipVO?.stockCode} </Descriptions.Item>
                </Descriptions>
                {/* 货物信息 */}
                <Descriptions title={<div style={{ display: 'flex', justifyContent: "space-between" }}>
                    <div><Divider type="vertical" style={{ border: '1px solid #1C6CDC ' }} />货物信息</div>
                    <div onClick={() => settrackInfo(true)} style={{ fontSize: 12, color: "#155BD4", cursor: "pointer" }}><img src={trackIcon} /> 轨迹回顾</div>
                </div>} >
                    <Descriptions.Item label="品名" span="3">{tipVO?.title}</Descriptions.Item>

                    <Descriptions.Item label="数量" >{tipVO?.number}</Descriptions.Item>
                    <Descriptions.Item label="体积(m)" >{tipVO?.volume}</Descriptions.Item>
                    <Descriptions.Item label="毛重" >{tipVO?.weight} </Descriptions.Item>

                    <Descriptions.Item label="货值(元)" >{tipVO?.amount}</Descriptions.Item>
                    <Descriptions.Item label="单价(元)" >{tipVO?.price}</Descriptions.Item>
                    <Descriptions.Item label="浮动盈亏" >{tipVO?.totalProfitLoss} </Descriptions.Item>

                    <Descriptions.Item label="信用证到期日" span="3">{tipVO?.expire} {tipVO?.expire && <span style={{ color: 'red', paddingLeft: 10 }}>即将过期</span>} </Descriptions.Item>
                </Descriptions>
                {/* 货物跟踪 */}
                <div className="storage_title"><Divider type="vertical" style={{ border: '1px solid #1C6CDC ' }} />货物跟踪</div>
                <div>现场监控：</div>

                {vedioInfo?.accessToken &&
                    <div style={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
                        <div style={{ position: 'absolute', color: 'red', top: '5px', right: '4px', zIndex: '9999', cursor: 'pointer' }}
                            onClick={
                                () => { handleVedioOk(tipVO?.code); }
                            }>
                            <img src={enlargeIcon} alt="" style={{ width: 24 }} />

                        </div>
                        {!isModalOpen ? <OnlineVedio
                            key="hz"
                            width={videowidth}
                            height={videoHeight}
                            onRef={ChildRef_bank}
                            accessToken={vedioInfo?.accessToken}
                            url={vedioInfo?.video}

                        ></OnlineVedio> : <div style={{ width: videowidth, height: videoHeight, background: 'gray' }}> <Modal title="监控视频"
                            key="hz"
                            zIndex={10009}
                            open={isModalOpen}
                            width={1020}
                            footer={null}
                            onOk={handleOk}
                            onCancel={handleCancel}>

                            {isModalOpen &&
                                <div style={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>

                                    <OnlineVedio
                                        key="hz"
                                        width={vedioModWidth}
                                        height={vedioModHeight}
                                        onRef={ChildRef_bank}
                                        accessToken={vedioInfo?.accessToken}
                                        url={vedioInfo?.video}
                                    ></OnlineVedio>
                                    <div className="box" style={{ width: vedioModWidth, height: vedioModHeight }}>
                                        {pic?.url && <img style={{ width: "100%", height: "100%" }} className="" src={pic?.url} />}
                                    </div>
                                </div>
                            }


                        </Modal></div>}
                        <div className="box" style={{ width: videowidth, height: videoHeight }}>
                            {pic?.url && <img style={{ width: "100%", height: "100%" }} className="" src={pic?.url} />}
                        </div>
                    </div>
                }

                <div style={{ marginTop: 24 }}>库存监控：</div>
                {storeInfo?.applyList?.length > 0 ? <Steps
                    className="storage_steps"
                    size="small"
                    labelPlacement="vertical"
                    items={getItem(storeInfo)}
                ></Steps> :
                    <div className="empty_store" style={{ display: "flex", alignItems: 'center', flexDirection: 'column' }}>
                        <img style={{ width: 64 }} src={empty_store} />
                        <div>暂无库存展示</div>
                    </div>
                }
            </div>
        </Drawer>
        <DetailDrawer
            setshowDrawer={settrackInfo}
            visible={trackInfo}
            isHiddenHead={isHiddenHead}
            tipVO={tipVO}
            portInfo={portInfo}
            id={id}
            current={current}
            isShowcloseBtn={true}
            marginLeft={520}
        />



    </>
}
export default StorageDrawer;